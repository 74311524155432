.step-i {
  padding: 6px 24px;
  font-size: 2.9rem;
  color: white;
  font-weight: 900;
  background-color: #fa0000;
  border-radius: 15px;
  justify-content: center;
  display: flex;
  align-items: center;
  box-shadow: 10px 10px 5px 0px rgba(180, 180, 180, 0.75);
  -webkit-box-shadow: 10px 10px 5px 0px rgba(180, 180, 180, 0.75);
  -moz-box-shadow: 10px 10px 5px 0px rgba(180, 180, 180, 0.75);
}

.bigPaginator {
  // display: flex;
  // justify-content: space-between;
  // margin-left: 14%;
  // width: 53%;
  // max-width: 80%;
  // // width: 62.5rem;
  // margin-top: 1%;
  // margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  // padding: 0px 13%;
}

.playicom {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 3%;
  cursor: pointer;
}

.mr-lft {
  margin-right: 15px;
}

.addion {
  // margin-right: 15px;
  // padding: 5px !important;

  .MuiSvgIcon-root {
    font-size: 4.5rem !important;
  }
}

.play-itself {
  color: white;
  // box-shadow: 10px 10px 5px 0px rgba(180,180,180,0.75);
}

.sim-play-font {
  font-size: 1.4rem;
  font-weight: 700;
}

.spec-play-font {
  font-size: 1.2rem;
  font-weight: 700;
  margin-right: 15px;
}

.ant-btn-primary {
  background: red !important;
  border-color: red !important;
}

.bold {
  font-weight: bold;
}

.popover-title {
  display: flex;
  justify-content: space-between;
}

.txt-color {
  color: #e4003b;
}

.num-btn {
  width: 4.5rem;
  height: 4.5rem;
}

.first-page-paginator {
  width: 17rem !important;
}

.paginator {
  // width: 62.5rem;
  display: flex;
  margin-left: 15rem;
}

.paginator-first-div {
  width: 62.5rem;
}

.paginator-second-div {
  display: flex;
  justify-content: space-between;
  margin-top: -20px;
}

// @media screen and (min-width: 1024px) {

//   .paginator-first-div{
//     width: 62.5rem !important;
//   }
// }

@media (min-width: 360px) and (max-width: 390px) {
  .num-btn {
    height: 4rem;
    width: 4rem;
  }

  .paginator {
    margin: 0 5%;
  }

  .sim-play-font {
    display: none;
  }
}

@media (min-width: 1024px) and (max-width: 1280px) {
  .paginator {
    margin-left: 12rem;
  }

  .first-page-paginator {
    margin-left: 9rem;
  }
}

@media (max-width: 3840px) {
  .first-page-paginator {
    margin-left: 44.6rem;
  }
}

@media (max-width: 3440px) {
  .first-page-paginator {
    margin-left: 41.4rem;
  }
}

@media (max-width: 2880px) {
  .first-page-paginator {
    margin-left: 37rem;
  }
}

@media (max-width: 2560px) {
  .first-page-paginator {
    margin-left: 34.4rem;
  }
}

@media (max-width: 2048px) {
  .first-page-paginator {
    margin-left: 30.3rem;
  }
}

@media (max-width: 1920px) {
  .first-page-paginator {
    margin-left: 29.3rem;
  }
}

@media (max-width: 1680px) {
  .first-page-paginator {
    margin-left: 27.4rem;
  }
}

@media (max-width: 1600px) {
  .first-page-paginator {
    margin-left: 26.8rem;
  }
}

@media (max-width: 1440px) {
  .first-page-paginator {
    margin-left: 25.5rem;
  }
}

@media (max-width: 1400px) {
  .first-page-paginator {
    margin-left: 25.2rem;
  }
}

@media (max-width: 1366px) {
  .first-page-paginator {
    margin-left: 24.9rem;
  }

  .aligner-for-head {
    width: 55%;
  }
}

@media (max-width: 1280px) {
  .paginator-first-div {
    margin-right: 5rem;
  }

  .first-page-paginator {
    margin-left: 24.2rem;
  }

  .aligner-for-head {
    width: 60%;
  }
}

@media (max-width: 1152px) {
  .first-page-paginator {
    margin-left: 23.2rem;
  }

  .aligner-for-head {
    width: 65%;
  }
}

@media (max-width: 1024px) {
  .paginator {
    margin-left: 8rem;
  }

  .first-page-paginator {
    margin-left: 22.2rem;
  }

  .aligner-for-head {
    width: 70%;
  }
}

@media (max-width: 800px) {
  .paginator {
    margin-left: 8rem;
  }

  .first-page-paginator {
    margin-left: 14.6rem;
  }

  .aligner-for-head {
    width: 70%;
  }
}

@media (max-width: 480px) {
  .heading-container {
    height: 5pc;
  }

  .paginator {
    margin-left: 2.2rem;
  }

  .first-page-paginator {
    margin-left: -9rem;
  }

  .paginator-first-div {
    margin-right: 2.2rem;
  }

  .num-btn {
    height: 4rem;
    width: 4rem;
  }

  .sim-play-font {
    display: none;
  }

  .m-top {
    // margin-top on screen two four five six
    margin-top: 1.5rem;
  }
}