.landing-pg-main-container {
    height: 38pc;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.landing-pg-inner-con {
    text-align: center;
    width: 50pc;
}

.landing-pg-main-heading {
    font-size: 3rem;
    font-weight: 700;
}

.custom-main-heading {
    font-size: 3.5rem;
}

.tag-line {
    color: #FA0000;
    font-size: 1.1rem;
    font-weight: 600;
}

.attorney-guide,
.subpoena-lang {
    font-size: 1.5rem;
    text-decoration: underline;
}

.cell-tower-locations {
    font-size: 1.5rem;
}

.options-first-letter {
    font-size: 2rem;
    font-weight: 600;
}

.options-remaining-letters {
    font-size: 1.7rem;
    font-weight: 600;
}

.case-type-dropdown {
    width: 12pc;
    bottom: 10%;
}