.main-con-one {
    width: 100%;
    margin-top: 1%;
}

.inner-con-one {
    padding: 0% 7%;
}

.normalForOne {
    font-size: 2.5rem;
    font-weight: 700;
}

.customForOne {
    font-size: 2.9rem;
}

.head-Red {
    color: #fa0000;
    font-size: 1.8rem;
    font-weight: 700;
}

.aligner-for-head {
    display: flex;
    flex-direction: column;
    line-height: 1.3;
    height: 8pc;
    width: 50%;
}

.heading-container {
    display: flex;
    padding-left: 7%;
    height: 10pc;
}

.block {
    height: 29rem;
}

.normal-D {
    font-size: 1.3rem;
    color: black;
    font-weight: 800;
    text-align: start;
    line-height: 1.1;
}

.special-D {
    font-size: 1.5rem;
}

.ml {
    margin-left: 30px;
}

.one-one {
    display: flex;
    margin-right: 4%;
    align-items: flex-start;
    justify-content: flex-end;
}

.row-i-for-one {
    margin-top: 50px;
    display: flex;
    justify-content: space-evenly;
}

.row-ii-for-one {
    margin-top: 80px;
    display: flex;
    justify-content: space-evenly;
}

.outer-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.wakhra {
    font-size: 1.2rem;
}

.one-two {
    display: flex;
    margin-left: 4%;
    align-items: flex-start;
}

.diffier {
    font-size: 1.3rem;
    color: black;
    font-weight: 800;
    line-height: 1.1;
}

.mr {
    margin-right: 20px;
}

.payD {
    width: 16em;
}

.pdAlign {
    display: flex;
    flex-direction: column;
}

.bg-play {
    background-color: #fa0000;
    display: flex;
    align-items: center;
    border-radius: 15px;
    color: white;
    font-weight: 900;
    margin-right: 15px;

    .MuiSvgIcon-root {

        font-size: 4rem !important;
    }
}

.black-btn {
    background-color: black !important;
}

.pm {
    margin-bottom: 0 !important;
}

.mt {
    margin-top: 0.5rem !important;
}

.fs {
    font-size: 16px;
}

.btn-size-one {
    height: 4.5rem;
    width: 4.5rem;
}

.outer {
    display: flex;
    gap: 7rem;
    margin-top: -1rem;
}

.each-type {
    display: flex;
    align-items: center;
    height: 6rem;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 20px;
}

.grid-item {
    display: flex;
    padding: 13px 13px 13px 0px;
    text-align: center;
}

@media only screen and (max-width: 1023px) {
    .outer {
        display: none;
    }

    .grid-container {
        display: inline-grid;
        margin: 4% 0% 0% 7%;
        ;
    }

    .head-Red {
        font-size: 1rem;
    }

    .testing-image {
        width: 160px !important;
        height: 150px !important;
    }

    .normalForOne {
        font-size: 2rem;
    }

    .customForOne {
        font-size: 2.5rem;
    }
}

@media only screen and (min-width: 1024px) {
    .grid-container {
        display: none;
    }
}

.testing-image {
    width: 190px;
    height: 180px;
}


/* Media Query for Mobile Devices */
@media (min-width: 300px) and (max-width: 480px) {
    .inner-con-one {
        padding: 0px !important;
    }

    .testing-image {
        width: 100px !important;
        height: 90px !important;
    }

    .customForOne {
        font-size: 1.3rem !important;
    }

    .normalForOne {
        font-size: 1rem !important;
    }

    .normal-D {
        font-size: 1rem !important;
    }

    .special-D {
        font-size: 1.2rem !important;
    }

    .payD {
        width: 12em !important;
    }

    .mtop {
        margin-top: 50px !important;
    }
}